import {createSlice} from '@reduxjs/toolkit';
import {cloneDeep} from 'lodash';

import {ServiceTypeAllows, SportType} from '../../interfaces/interfaces';
export interface SportState {
	allCity: string[];
	filterSport: string;
	allServicesTypes: string[];
	allowsServiceType: ServiceTypeAllows[];
	allSportTypes: SportType[];
	arrSportString: string[];
	arrServiceTypeString: string[];
	objAllows: {[key: string]: string[]} | null;
}

const initialState: SportState = {
	allCity: [],
	filterSport: 'Все виды',
	allServicesTypes: [],
	allowsServiceType: [],
	allSportTypes: [],
	arrSportString: [],
	arrServiceTypeString: [],
	objAllows: null,
};

export const sportSlice = createSlice({
	name: 'sportSlice',
	initialState,
	reducers: {
		setAllCity: (state: SportState, action) => {
			state.allCity = [...action.payload.data];
		},
		setObjAllows: (state: SportState, action) => {
			state.objAllows = {...action.payload};
		},
		resetServiceType: (state: SportState) => {
			state.arrServiceTypeString = [];
			state.allServicesTypes = [];
		},
		setArrServiceTypeString: (state: SportState, action) => {
			state.arrServiceTypeString = action.payload.data;
		},
		resetSport: (state: SportState) => {
			state.arrSportString = [];
			state.allSportTypes = [];
		},
		setArrSportString: (state: SportState, action) => {
			state.arrSportString = action.payload.data;
		},
		setFilterSport: (state: SportState, action) => {
			state.filterSport = action.payload.filter;
		},
		resetFilterSport: (state: SportState) => {
			state.filterSport = 'Все виды';
		},
		setAllSportType: (state: SportState, action) => {
			state.allSportTypes = [...action.payload.data];
		},

		setAllowsServiceType: (state: SportState, action) => {
			state.allowsServiceType = [...action.payload.data];
		},

		setServicesTypes: (state: SportState, action) => {
			state.allServicesTypes = action.payload.data;
		},

		addSportType: (state: SportState, action) => {
			state.allSportTypes = [...state.allSportTypes, action.payload.data];
		},

		deleteSportType: (state: SportState, action) => {
			state.allSportTypes = state.allSportTypes.filter((el) => el.sport !== action.payload);
		},

		updateSportType: (state: SportState, action) => {
			state.allSportTypes = state.allSportTypes.map((el) => {
				if (el.sport === action.payload.sport_type) {
					return {
						sport: action.payload.new_name,
						logo: action.payload.new_logo,
					};
				}

				return el;
			});
		},

		addServiceType: (state: SportState, action) => {
			state.allServicesTypes = [...cloneDeep(state.allServicesTypes), action.payload];
		},

		deleteServiceType: (state: SportState, action) => {
			state.allServicesTypes = state.allServicesTypes.filter((el) => el !== action.payload);
		},

		updateServiceType: (state: SportState, action) => {
			state.allServicesTypes = state.allServicesTypes.map((el) => {
				if (el === action.payload.old_service_type) {
					return action.payload;
				}

				return el;
			});
		},
	},
});

export const {
	setAllCity,
	setObjAllows,
	resetServiceType,
	setArrServiceTypeString,
	resetSport,
	setArrSportString,
	setFilterSport,
	setAllSportType,
	resetFilterSport,
	setAllowsServiceType,
	setServicesTypes,
	addSportType,
	updateSportType,
	deleteSportType,
	addServiceType,
	deleteServiceType,
	updateServiceType,
} = sportSlice.actions;
