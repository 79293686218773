import {FC, useState} from 'react';

import noImagePlaceholder from '../../../../../assets/img/no_image.png';
import IconSporder from '../../../../../components/svg-icon-components/icon-sporder';
import {PlatformListItem} from '../../../../../interfaces/platform';
import {
	OrganizationCardModal
} from '../../../../../pages/organization-list-page/components/organization-list/components/organization-card/components/organization-card-modal/organization-card-modal';
import {useAppSelector} from '../../../../../redux/selectors/hook';
import {allModeratorsOrgSelector} from '../../../../../redux/selectors/org-state-selectors';
import {useAppDispatch} from '../../../../../redux/store';
import {
	fetchGetModeratorsByOrganization
} from '../../../../../redux/thunks/organization/get-moderators-by-organization-thunk';
import {ONE_FIELDS} from '../../../../../shared/constants/path';
import {useResizeWindow} from '../../../../../shared/hooks/use-resize-window';
import {filterRegisteredUsers} from '../../../../../shared/utils/filters/filter-registred-users';
import {handleScrollToTop} from '../../../../../shared/utils/scroll-to-top';
import {ButtonGroupSettingPlatform} from '../../../../buttons';
import {QuickJumpButton} from '../../../../buttons/quick-jump-button/quick-jump-button';
import {Spoiler} from '../../../../Spoiler/Spoiler';
import {PlatformCardContactsList} from '../platform-card-contacts-list/platform-card-contacts-list';
import * as SC from './platform-list-card.style';

export interface Props {
	place?: 'sidebar-list' | 'list';
	platform: PlatformListItem;
	className?: string;
	onClickToPlatform: (id: string) => void;
	onClickToOrg: (org_name: string) => void;
	isAdmin?: boolean;
	refetch: () => void
}

export const PlatformListCard: FC<Props> = ({
												platform,
												onClickToPlatform,
												onClickToOrg,
												isAdmin = false,
												refetch,
											}) => {
	const NAV_TO = ONE_FIELDS.replace(':id', platform.id);
	const width = useResizeWindow();
	const isMobileMod = width <= 1350;
	const dispatch = useAppDispatch();
	const allModerators = useAppSelector(allModeratorsOrgSelector);
	const [showAllModerators, setShowAllModerators] = useState(false);
	const onClickToPlatformHandler = () => handleScrollToTop();

	const ownersClick = () => {
		dispatch(fetchGetModeratorsByOrganization({platform_id: platform.id})).then(() => setShowAllModerators(true));
	};

	return (
		<SC.PlatformCard>
			<SC.PlatformCardLogoWrapper>
				<SC.PlatformCardLogo
					src={platform.logo || noImagePlaceholder}
					alt={`Изображение - ${platform?.org_name}`}
					title={`Изображение - ${platform?.org_name}`}
				/>
				<QuickJumpButton onClick={onClickToPlatformHandler} text={'Быстрый просмотр'} type={'link'}
								 to={NAV_TO}/>
			</SC.PlatformCardLogoWrapper>
			<SC.PlatformCardMainInfo active={platform.active}>
				<SC.PlatformCardTitle>{platform?.org_name}</SC.PlatformCardTitle>
				<div onClick={() => onClickToOrg?.(platform?.org_name)}>
					<SC.PlatformCardOrganizationLogo
						src={platform?.org_logo || noImagePlaceholder}
						alt={`Логотип - ${platform.org_name}`}
						title={`Логотип - ${platform.org_name}`}
					/>
				</div>
				<PlatformCardContactsList platform={platform}/>
			</SC.PlatformCardMainInfo>
			<SC.PlatformCardAdditionalInfo>
				<SC.ToMapLinkWrapper active={platform.active} to={NAV_TO}>
					<SC.ToMapLink onClick={() => onClickToPlatform?.(platform?.id || '')}>на карту</SC.ToMapLink>
					<IconSporder strokeColor="#1C8666" width={12} height={15}/>
				</SC.ToMapLinkWrapper>
				{!isMobileMod &&
					<SC.PlatformCardInfoBlockDescription
						active={platform.active}>{platform?.description}</SC.PlatformCardInfoBlockDescription>}
				{isMobileMod && <SC.SpoilerWrapper active={platform.active}><Spoiler
					description={platform?.description}/></SC.SpoilerWrapper>}
				<ButtonGroupSettingPlatform platform={platform} isAdmin={isAdmin} refetch={refetch}
											ownersClick={ownersClick}/>
			</SC.PlatformCardAdditionalInfo>
			{showAllModerators && <OrganizationCardModal
				type={'owners'} formType={false}
				allOwners={filterRegisteredUsers(allModerators.map(users => users.user_info))}
				handleIsType={() => setShowAllModerators(false)} style={isMobileMod ? SC.OrganizationCardModal : {
					bottom: '74px',
					left: '70%',
					position: 'absolute'
				}}
			/>}
		</SC.PlatformCard>
	);
};
