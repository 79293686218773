import {FC, useCallback, useState} from 'react';
import {useLocation} from 'react-router-dom';

import {AbonementType} from '../../../interfaces/abonement';
import {useAppDispatch} from '../../../redux/store';
import {fetchSetPayment} from '../../../redux/thunks/payment/set-payment';
import {HOST_PAYMENT, urlNotifyPayment} from '../../../shared/constants/url';
import {isRightTokenTime} from '../../../shared/utils/decoder-jwt';
import {getMonthsLabel, getWeeksLabel} from '../../../shared/utils/get-date-label';
import {isAuth, isJwt} from '../../../shared/utils/save-local-storage';
import {converterTime} from '../../../shared/utils/time/converter-time';
import {ModalPhoneOrder} from '../../modal-phone-order/modal-phone-order';
import IconTime from '../../svg-icon-components/icon-time';
import {IconLock} from '../../svg-icon-components/Lock';
import * as SC from './abonement-new.style';
import {ByeButton} from './components/bye-button/bye-button';
import {DetailWrapper} from './components/detail-wrapper/detail-wrapper';
import {ModalDetails} from './components/modal-details-abonement/modal-details-abonement';
import {Name} from './components/name/name';
import {OrgInfo} from './components/org-info/org-info';

interface AbonementNewProps {
	abonement: AbonementType;
}


export const AbonementNew: FC<AbonementNewProps> = ({abonement}) => {
	const BG_ICON = 'var(--yellow)';
	const dispatch = useAppDispatch();
	const [showDetails, setShowDetails] = useState(false);
	const [showDetailsAbonement, setShowDetailsAbonement] = useState<AbonementType | null>(null);
	const [phone, setPhone] = useState('');
	const [openPhoneWindow, setOpenPhoneWindow] = useState(false);
	const location = useLocation();

	const onClickBye = () => {
		const phoneNumber = phone.replace(/[\s()\-]/g, '');
		dispatch(fetchSetPayment({
			notification_url: `${HOST_PAYMENT}${urlNotifyPayment}`,
			issuer: {Abonement: {id: abonement.id}},
			requester: {Anonym: {phone: phoneNumber}},
			success_url: `${HOST_PAYMENT}${location.pathname}`,
			fail_url: `${HOST_PAYMENT}${location.pathname}`,
		}));
		setOpenPhoneWindow(false);
	};
	const changePhone = useCallback((e: string) => {
		setPhone(e);
	}, [phone]);
	const closeWindowPhone = useCallback(() => {
		setOpenPhoneWindow(false);
	}, [openPhoneWindow]);
	const closeWindow = () => {
		setShowDetailsAbonement(null);
		setShowDetails(false);
		document.body.style.overflow = 'auto';
	};
	const clickShowDetails = () => {
		setShowDetailsAbonement(abonement);
		setShowDetails(true);
		document.body.style.overflow = 'hidden';
	};

	return (
		<SC.Container>
			<SC.ContentWrapper>
				<Name title={abonement.info.name}/>
				<SC.Hr/>
				<OrgInfo logo={abonement.org_info.logo}
						 address={`${abonement.platform_info.city}, ${abonement.platform_info.location_info.address}`}
						 orgName={abonement.org_info.org_name}/>
				<SC.DataContainer>
					<DetailWrapper label={'Количество занятий'} icon={<IconLock backgroundColor={BG_ICON}/>}
								   text={abonement.info.limit !== null ? abonement.info.limit.toString() : 'Безлимитно'}
								   unLimit={abonement.info.limit === null}/>
					<DetailWrapper label={'Длительность абонемента'} icon={<IconTime backgroundColor={BG_ICON}/>}
								   text={'Month' in abonement.info.duration
									   ? `${abonement.info.duration.Month} ${getMonthsLabel(abonement.info.duration.Month)}`
									   : `${abonement.info.duration.Week} ${getWeeksLabel(abonement.info.duration.Week)}`}/>
					<DetailWrapper label={'Время для посещения'} icon={<IconTime backgroundColor={BG_ICON}/>}
								   text={abonement.info.working_from !== null ?
									   `с: ${converterTime(abonement.info.working_from || 0).replace('.', ':')}
									   до: ${converterTime(abonement.info.working_to || 0).replace('.', ':')}` : 'Круглосуточно'}/>
				</SC.DataContainer>
				<SC.HasAbonement>Доступно в абонементе:</SC.HasAbonement>
				<SC.ListServices>
					{abonement.services.map(i => {
						return <li key={i.id}>{i.service_type}</li>;
					})}
				</SC.ListServices>
			</SC.ContentWrapper>
			<SC.ButtonsWrapper>
				<ByeButton id={abonement.id} setOpenPhoneWindow={setOpenPhoneWindow} price={abonement.info.price}/>
				<SC.DetailsButton onClick={clickShowDetails}>Подробности абонемента</SC.DetailsButton>
			</SC.ButtonsWrapper>
			{openPhoneWindow &&
				<ModalPhoneOrder closeWindow={closeWindowPhone} orderClick={onClickBye}
								 onChangePhone={changePhone}
								 description={(isAuth() && isJwt() && !isRightTokenTime())
									 ? 'Время сеанса истекло. Авторизуйтесь снова или введите номер телефона'
									 : undefined} titleButton={'Купить'}/>}
			{(showDetails && showDetailsAbonement !== null) &&
				<ModalDetails services={abonement.services} abonement={showDetailsAbonement} closeWindow={closeWindow}
							  setOpenPhoneWindow={setOpenPhoneWindow} setShowDetailsAbonement={setShowDetailsAbonement}/>}
		</SC.Container>
	);
};
