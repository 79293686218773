import {CSSProperties, FC, useState} from 'react';
import {useNavigate} from 'react-router-dom';

import {PlatformListItem} from '../../../../interfaces/platform';
import {setCheckedPlatformUpdate} from '../../../../redux/reducers/platforms-reducer';
import {useAppDispatch} from '../../../../redux/store';
import {fetchDeactivatePlatform} from '../../../../redux/thunks/platform/deactivate-platform';
import {fetchRecoverPlatform} from '../../../../redux/thunks/recover-platform';
import {fetchRemovePlatform} from '../../../../redux/thunks/remove-platform';
import {ADMIN, UPDATE_PLATFORM} from '../../../../shared/constants/path';
import {ConfirmDeletion} from '../../../confirm-deletion/confirm-deletion';
import {ButtonCard} from '../../button-card/button-card';
import * as SC from './button-group-setting-platform.style';

interface ButtonGroupSettingProps {
	isAdmin: boolean;
	platform: PlatformListItem;
	style?: CSSProperties
	refetch: () => void
	ownersClick: () => void
}

export const ButtonGroupSettingPlatform: FC<ButtonGroupSettingProps> = ({
																			style,
																			isAdmin,
																			platform,
																			refetch,
																			ownersClick
																		}) => {
	const [type, setType] = useState('');
	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	const removeClickHandler = () => {
		dispatch(fetchRemovePlatform({id: platform.id})).then(() => refetch());
		setType('');
	};
	const recoverClickHandler = () => {
		dispatch(fetchRecoverPlatform({id: platform.id})).then(() => refetch());
		setType('');
	};

	const updateClickHandler = () => {
		dispatch(setCheckedPlatformUpdate(platform));
		navigate(`${ADMIN}platforms/${UPDATE_PLATFORM}`);
	};
	const deactivateClickHandler = () => {
		dispatch(fetchDeactivatePlatform({id: platform.id})).then(() => refetch());
		setType('');
	};

	return (
		<>
			{isAdmin && platform.active &&
				<SC.CardRemove style={style}>
						<ButtonCard title={'Все модераторы'} background={'#CB8B2A;'}
									buttonClick={ownersClick}/>
					<ButtonCard title={'Изменить'} background={'#1C8666'} buttonClick={updateClickHandler}/>
					<SC.ButtonWrapper>
						{type === 'deactivate' &&
							<ConfirmDeletion type={'recover'} remove deleteFalse={() => setType('')}
											 deleteTrue={deactivateClickHandler}
											 style={SC.ConfirmDeletion}/>}
						<ButtonCard buttonClick={() => setType('deactivate')} title={'Деактивировать'}
									background={'#352C8D'}/>
					</SC.ButtonWrapper>
				</SC.CardRemove>
			}
			{isAdmin && !platform?.active &&
				<SC.CardRemove style={style}>
					<ButtonCard title={'Изменить'} background={'#1C8666'} buttonClick={updateClickHandler}/>
					<ButtonCard buttonClick={recoverClickHandler} title={'Активировать'}
								background={'#352C8D'}/>
					<SC.ButtonWrapper>
						{type === 'remove' && <ConfirmDeletion type={type} remove deleteFalse={() => setType('')}
															   deleteTrue={removeClickHandler}
															   style={SC.ConfirmDeletion}/>}
						<ButtonCard buttonClick={() => setType('remove')} title={'Удалить'}
									background={'var(--yellow)'}/>
					</SC.ButtonWrapper>
				</SC.CardRemove>}
		</>
	);
};
