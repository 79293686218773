import {FC, ReactNode, useEffect, useRef} from 'react';

import * as SC from './modal-window.style';

interface ModalWindowProps{
	closeWindow: ()=>void;
	children: ReactNode;
	styleWindow?: string
	iconClose?: boolean
}

export const ModalWindow: FC<ModalWindowProps> = ({closeWindow, styleWindow, children, iconClose = true}) => {
	const elementRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (elementRef.current && !elementRef.current.contains(event.target as Node)) {
				closeWindow();
			}
		};

		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [closeWindow]);

	return (
		<SC.Container>
			<SC.Window ref={elementRef} className={styleWindow}>
				<SC.ContentWrapper>
					{children}
					{iconClose && <SC.CloseBtn onClick={closeWindow}>X</SC.CloseBtn>}
				</SC.ContentWrapper>
			</SC.Window>
		</SC.Container>
	);
};
