import {css} from '@emotion/css';
import styled from '@emotion/styled';

export const Title = styled.h3`
	font-size: 28px;
	color: var(--link-color);
`;

export const Button = {
	width: '100%',
};
export const StyleWindow = css`
  width: 450px !important;
  @media screen and (max-width: 450px) {
    width: 100% !important;
  }
`;
