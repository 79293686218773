import {Dispatch, FC, SetStateAction} from 'react';

import {AbonementType} from '../../../../../interfaces/abonement';
import {getMonthsLabel, getWeeksLabel} from '../../../../../shared/utils/get-date-label';
import {converterTime} from '../../../../../shared/utils/time/converter-time';
import {Button} from '../../../../buttons';
import * as SC from './still-abonement-card.style';

interface StillAbonementCardProps {
	abonement: AbonementType;
	setShowDetailsAbonement: Dispatch<SetStateAction<AbonementType | null>>;
}

export const StillAbonementCard: FC<StillAbonementCardProps> = ({abonement, setShowDetailsAbonement}) => {

	return (
		<SC.Container>
			<SC.Title>{abonement.info.name}</SC.Title>
			<SC.DataList>
				<li>{abonement.info.limit} занятий</li>
				<li>{'Month' in abonement.info.duration
					? `${abonement.info.duration.Month} ${getMonthsLabel(abonement.info.duration.Month)}`
					: `${abonement.info.duration.Week} ${getWeeksLabel(abonement.info.duration.Week)}`}</li>
				<li>{abonement.info.working_from !== null ?
					`с: ${converterTime(abonement.info.working_from || 0).replace('.', ':')}
									   до: ${converterTime(abonement.info.working_to || 0).replace('.', ':')}` : 'Круглосуточно'}</li>
			</SC.DataList>
			<SC.Price>{(abonement.info.price / 100).toFixed(2)} BYN.</SC.Price>
			<Button label={'Подробнее'} bgColor={'green'} style={SC.Button} onClick={()=> setShowDetailsAbonement(abonement)}/>
		</SC.Container>
	);
};
