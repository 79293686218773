import {FC, memo} from 'react';

import {AbonementType} from '../../interfaces/abonement';
import {AbonementNew} from './abonement-new/abonement-new';
import * as SC from './abonements.style';

interface AbonementsInterface {
	abonements: AbonementType[]
}

export const Abonements: FC<AbonementsInterface> = memo(({abonements}) => {

	return (
		<SC.Container>
			{abonements.map(i => {
					return <AbonementNew key={i.id} abonement={i} />;
			})}
		</SC.Container>
	);
});
